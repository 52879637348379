import React from 'react';
import logoo from '../assets/logoo.png';

const FooterComponent = () => {
  return (
    <footer className="bg-gradient-to-br from-purple-900 via-black via-45% to-purple-950 text-white mt-0 py-10 px-8 lg:px-12">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row justify-between space-y-8 lg:space-y-0">
          <div className="flex flex-col sm:items-start sm:text-left lg:items-start lg:text-left">
            <div className="flex items-center relative">
              <img
                src={logoo}
                alt="Logo"
                className="mr-0 w-10 h-10"
              />
              <h1 className="text-3xl font-bold text-white">SAWTIFY</h1>
            </div>

            <div className="flex space-x-3 mt-4">
              <a
                href="mailto:hello@sawtify.io"
                className="bg-gradient-to-bl from-purple-800 via-pink-500 to-orange-400 p-3 rounded-full transform transition-transform duration-200 hover:scale-110"
              >
                <img
                  src="https://img.icons8.com/?size=100&id=38158&format=png&color=FFFFFF"
                  alt="Email"
                  className="w-5 h-5 md:w-6 md:h-6"
                />
              </a>

              <a
                href="https://www.linkedin.com/company/sawtifyai/"
                className="bg-gradient-to-bl from-purple-800 via-pink-500 to-orange-300 p-3 rounded-full transform transition-transform duration-200 hover:scale-110"
              >
                <img
                  src="https://img.icons8.com/?size=100&id=8808&format=png&color=FFFFFF"
                  alt="LinkedIn"
                  className="w-5 h-5 md:w-6 md:h-6"
                />
              </a>
            </div>
          </div>
        </div>

        <div className="border-t py-1 border-gray-400 w-full mt-8"></div>

        <div className="mt-6 text-center font-space-grotesk text-sm">
          Copyright © 2024 Sawtify. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
